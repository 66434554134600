import React from 'react'
import Layout from '../../components/Layout'
import PartOfLenses from '../../components/careers/PartOfLenses'
import BeYou from '../../components/careers/BeYou'
import HeaderSEO from '../../components/partials/HeaderSEO'

interface PageSEO {
  description: Description
  keywords: Keywords
  title: Title
  oGimage: OGimage
}

interface OGimage {
  fluid: Fluid
}
interface Fluid {
  src: string
}
interface Keywords {
  keywords: string
}
interface Description {
  description: string
}
interface Title {
  title: string
}

const technicalSupportEngineer = () => {
  // Matching to HeaderSEO format
  const pageSEO: PageSEO = {
    description: {
      description:
        'We are looking for a Technical Support Engineer in Raleigh, NC. Apply to join our team!',
    },
    keywords: {
      keywords:
        'Technical Support Engineer, Raleigh,Lenses careers,Hiring,Job opening',
    },
    oGimage: {
      fluid: {
        src: '//images.ctfassets.net/tnuaj0t7r912/U4bHsT7mf4gfY3It07UfG/056f681641ee77e2c1ba35a8fcb242f4/technical-support-engineer.jpeg',
      },
    },
    title: {
      title: 'Technical Support Engineer',
    },
  }

  const link = 'https://apply.workable.com/lensesio/j/392B5B1359/'

  return (
    <Layout>
      <HeaderSEO pageSEO={pageSEO} />
      <div className=" bg-dark py-5 my-5"></div>
      <br />
      <br />
      <div className="container-1 mb-5">
        <div className="card offset-md-2 col-md-8 col-sm-12">
          <div className="card-body pl-4 pr-4">
            <section className="section section--header">
              <div className="roll-button">
                <a
                  href="/careers/#openpositions"
                  className="bg-red text-white btn-xs mr-0 border-0 all-jobs-negative-margin"
                >
                  <i className="fa fa-arrow-left mr-1"></i>
                  <span data-hover="All jobs">All jobs</span>
                </a>
              </div>
            </section>
          </div>
          <h1 className="h3-like">Technical Support Engineer</h1>
          <p className="meta">Raleigh, NC </p>
          <section className="section section--text">
            <h5 className="pt-3 fw-600">Description</h5>
            <p>
              The Lenses.io SRE team is expanding, and you could be our next,
              new valuable member!
            </p>
            <p>
              Engineers love our product because it makes their life easier. As
              a Lenses Support Engineer, you will share this very same
              attribute. You will help prospective and active customers’
              engineering teams to set up and use Lenses.
            </p>

            <p>
              Your daily life will be talking with other engineers, ranging from
              startups to household names, helping them overcome Linux, Docker,
              Kubernetes, Helm, Kafka, Kafka Connect, networking, configuration,
              and other issues. It doesn’t just sound technical; it is and could
              be your entry ticket to a career in DevOps, Platform, and Site
              Reliability Engineering. Once an issue is beyond your reach, the
              developers and senior devops of Lenses will take over to debug or
              issue a fix
            </p>

            <p>
              You will spend most of your time in Zendesk but also replicating
              reported issues. You will also spend time on calls, our Slack
              community, our open-source repos, and contributing to our
              documentation. As with most positions, there is also an
              administrative overhead. You will have to keep tabs on open
              tickets and make sure we keep our SLAs.
            </p>

            <p>
              You will be working closely with our SRE/DevOps and SRE/Platform
              teams, and if there is a slow day, you will be able to contribute
              to these teams too —but it is not mandatory.
            </p>

            <h5 className="pt-3 fw-600">Requirements</h5>
            <h5 className="pt-3 fw-600">Primary Technology Stack to Support</h5>
            <p>
              Most support tickets have to do with a subject of the following
              list. Strong knowledge of a few of these subjects will help your
              application move to the top of the stack.
            </p>

            <ul className="pl-4 ">
              <li>Lenses configuration</li>
              <li>Lenses usage</li>
              <li>Lenses SQL</li>
              <li>Linux</li>
              <li>Docker</li>
              <li>Kubernetes</li>
              <li>Helm</li>
              <li>Apache Kafka</li>
              <li>Apache Kafka Connect</li>
              <li>Lenses Stream Reactor Connectors</li>
              <li>Schema Registry</li>
              <li>Amazon AWS</li>
              <li>Amazon MSK</li>
              <li>Azure HDInsight</li>
              <li>JVM</li>
              <li>LDAP</li>
              <li>Kerberos</li>
              <li>TLS/SSL</li>
              <li>Networking</li>
            </ul>
            <h5 className="pt-3">What you will bring:</h5>

            <p>
              Hopefully, we are going to spend many years working together. It’s
              important that we are compatible:
            </p>

            <ul className="pl-4 ">
              <li>
                You love learning systems in-depth, and you have the
                self-discipline to learn by yourself (we will still be there to
                teach and guide)
              </li>
              <li>Excellent communication and listening skills</li>
              <li>
                You have strong problem-solving skills and pay attention to
                details as they often can point to the root cause of the issue
              </li>
              <li>
                You are not afraid to admit when you do not know something and
                ask for help
              </li>
              <li>
                You are patient and willing to adjust your pace to the engineer
                you are currently helping
              </li>
              <li>
                You have some experience in computer science (or similar) and
                distributed systems
              </li>
              <li>
                You are not afraid to ask questions, give and receive feedback
              </li>
              <li>
                You have experience in some of our primary technology stack
              </li>
              <li>
                You are security, performance, and best-practice conscious
              </li>
              <li>You can take on responsibilities and own tasks</li>
              <li>
                You like working in a team, and you can push through language
                and cultural barriers to work with engineers all over the world
              </li>
            </ul>

            <PartOfLenses />
          </section>

          <section>
            <BeYou link={link} />
          </section>
        </div>
      </div>
    </Layout>
  )
}

export default technicalSupportEngineer
